
import {
    createRouter,
    createWebHashHistory,
} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'main',
        redirect: '/patientList',
    },

    {
        path: '/patientList',
        name: '详情',
        component: () => import('@/pages/patientList'),
    },

    {
        path: '/patientChatDetail',
        name: '聊天详情',
        component: () => import('@/pages/patientChatDetail'),
    },

    {
        path: '/imageBig',
        name: 'banner详情',
        component: () => import('@/pages/bannerDetail'),
    },
    {
        path: '/imageMulBig',
        name: 'banner详情页',
        component: () => import('@/pages/bannerMultipleDetail'),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
